// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container {
  background-color: #5dacbd;
}

/* Media query for viewport width > 576px */
@media (min-width: 576px) {
  .navbar-container {
    padding-left: 40px;
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}


.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #708914;
  margin-bottom: 20px;
  height: 70vh;
}

.inner-container {
  background-color: #1A202C;
  padding: 30px 25px 15px 25px;
  border-radius: 8px;
  max-width: 1200px;
  width: 100%;
  overflow: auto;
  height: 100%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 60%;
  max-height: 60%;
  overflow: auto;
  background-color: #2D3748;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-left: auto;
}

@media (max-width: 768px) {
  .main-container {
    height: 90vh;
  }

  .chat-container {
    height: 80%;
    max-height: 80%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA,2CAA2C;AAC3C;EACE;IACE,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,oBAAoB;EACtB;AACF;;;AAGA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,4BAA4B;EAC5B,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,eAAe;EACf,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,WAAW;IACX,eAAe;EACjB;AACF","sourcesContent":[".navbar-container {\n  background-color: #5dacbd;\n}\n\n/* Media query for viewport width > 576px */\n@media (min-width: 576px) {\n  .navbar-container {\n    padding-left: 40px;\n    padding-right: 100px;\n    padding-top: 30px;\n    padding-bottom: 20px;\n  }\n}\n\n\n.main-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #708914;\n  margin-bottom: 20px;\n  height: 70vh;\n}\n\n.inner-container {\n  background-color: #1A202C;\n  padding: 30px 25px 15px 25px;\n  border-radius: 8px;\n  max-width: 1200px;\n  width: 100%;\n  overflow: auto;\n  height: 100%;\n}\n\n.chat-container {\n  display: flex;\n  flex-direction: column;\n  height: 60%;\n  max-height: 60%;\n  overflow: auto;\n  background-color: #2D3748;\n  padding: 20px;\n  border-radius: 8px;\n  margin-bottom: 20px;\n  margin-left: auto;\n}\n\n@media (max-width: 768px) {\n  .main-container {\n    height: 90vh;\n  }\n\n  .chat-container {\n    height: 80%;\n    max-height: 80%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
