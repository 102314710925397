import React from "react";

const HomeView = () => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <p>
        CloudoneSoftware LLC, a US-based global software development firm, introduces NaijaE-Democracy, a cutting-edge platform designed to transform governance and citizen engagement in Nigeria. The platform offers secure polling and e-voting to enhance electoral integrity and improve transparency in governance. Additionally, it features a search bot tool and downloadable PDFs for easy verification of key regulatory frameworks, including the constitution, police act, petroleum act, and electoral act.
      </p>

      <h5>Our Mission</h5>
      <p>
        NaijaE-Democracy aims to bridge the gap between citizens and government by promoting transparency, accountability, and inclusive participation through advanced technology.
      </p>
    </div>
  );
};

export default HomeView;
